import React from "react";
import AppBar from "@mui/material/AppBar/index.js";
import Toolbar from "@mui/material/Toolbar/index.js";
import Typography from "@mui/material/Typography/index.js";
import { Link } from "react-router-dom";
import logo from "./img/logo_black.png";

export default function Header() {
  return (
    <div>
      <AppBar style={{ background: "#3fd4ad", color: "#333" }} position="static">
        <Toolbar style={{ justifyContent: "space-between" }}>
          {/* Логотип */}
          <Typography variant="h6">
            <Link to="/">
              <img src={logo} width="120px" alt="Logo" />
            </Link>
          </Typography>

          {/* Меню */}
          <nav style={{ display: "flex", gap: "20px" }}>
            <Link style={{ color: "#333", textDecoration: "none", fontSize: "16px" }} to="/">
              Головна
            </Link>
            <a
              style={{ color: "#333", textDecoration: "none", fontSize: "16px" }}
              href="/about.html"
              rel="noopener noreferrer"
            >
              Про проект
            </a>
          </nav>
        </Toolbar>
      </AppBar>
    </div>
  );
}
